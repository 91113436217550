import termsAndConditions from './assets/pdf/terms-and-conditions-rukipay.pdf';
import image from './assets/ruki-ilus-md@2x.png';
import './styles/app.scss';

function App() {
  return (
    <section className="main-section py-5 d-flex alignt-items-center">
      <div className="container ">
        <div className="row align-items-center h-100">
          <div className="col-12 col-lg-6 ">
            <h2>
              ¡Ahora somos <strong>Rukipay</strong>!
            </h2>
            <h1>Sitio en construcción</h1>
            <p>Te pedimos que aguardes mientras mejoramos para vos.</p>
            <p>
              Mientras tanto, podés leer nuestros{' '}
              <a href={termsAndConditions} download>
                Términos y condiciones
              </a>
            </p>
            <img src={image} alt="ilustracion sitio en construccion" className="img-fluid d-lg-none" />
          </div>
        </div>
      </div>
    </section>
  );

  // return (
  //   <BrowserRouter>
  //     <Main />
  //   </BrowserRouter>
  // );
}

export default App;
